<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4"/>
			<div class="page_1_center_box">
				<div class="cen-text">
					<span style="color: #00FFFB;">HI.</span> NICE TO MEET YOU.
				</div>
				<div class="bot-text">
					Any questions or requests, you can leave a message to us using the form below, we will reply within
					24 hours.
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_tab">
				<div class="tab_top">
					<div class="tab_left">
						<img class="tab_left_img" src="../../assets/images/contact/tab_1.png" />
						LEAVE A MESSAGE
					</div>
					<div class="tab_center">
						Or you can contact our sales team by
					</div>
					<div class="tab_right">
						<img class="tab_right_img" @click="open_whats"  src="../../assets/images/contact/tab_2.png" />
						<!-- <img class="tab_right_img" src="../../assets/images/contact/tab_3.png" /> -->
						<img class="tab_right_img" @click="open_skype"  src="../../assets/images/contact/tab_4.png" />
					</div>
				</div>
				<div class="tab_cent">
					<el-form :model="add_form" inline  ref="add_form" :rules="rules">
						<el-form-item label="" class="el-form-item-list" prop="firstName" >
							<el-input v-model="add_form.firstName" placeholder="User name *" clearable />
						</el-form-item>
						<el-form-item label="" class="el-form-item-list" prop="email">
							<el-input v-model="add_form.email" clearable placeholder="Email *" />
						</el-form-item>
						<el-form-item label="" class="el-form-item-list" prop="phone">
							<el-input v-model="add_form.phone" clearable placeholder="Phone" />
						</el-form-item>
						<el-form-item label="" class="el-form-item-list" prop="country">
							<el-input v-model="add_form.country" clearable placeholder="Country *" />
						</el-form-item>
						<el-form-item label="" class="el-form-item-list" prop="company">
							<el-input v-model="add_form.company" clearable placeholder="Company *" />
						</el-form-item>
						<el-form-item label="" class="el-form-item-list" prop="job">
							<el-input v-model="add_form.job" clearable placeholder="job" />
						</el-form-item>
						<el-form-item label="" class="el-form-item-list" prop="content" style="width: 100%;">
							<el-input v-model="add_form.content" type="textarea" placeholder="Message content *"
								:rows="6" clearable />
						</el-form-item>
					</el-form>
					<div class="sub-box">
						<!-- <el-input class="sub-input" v-model="add_form.division" clearable placeholder="Verification code" /> -->
						<!-- <div class="coe-img">
							
						</div> -->
						<div class="flex-center">
							<div id="dragContainer">
								<!-- 绿色区域 -->
								<div id="dragBg"></div>
								<!-- 滑动容器文本 -->
								<div id="dragText"></div>
								<!-- 拖动元素 -->
								<div id="dragHandler" class="dragHandlerBg"></div>
							</div>
							<!-- <div class="btn" @click="initDrag">初始化</div> -->
						</div>
						<el-button :disabled="is_dis" :style="is_dis ? 'background-color: darkgrey;' : 'background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);box-shadow: 0px 6px 20px 0px #ABDBF7;'" @click="sub_form" type="primary" class="sub-btn">
							<span>SUBMIT</span>
							<img class="bott-img" src="../../assets/images/next_2.png" alt="" />
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<PageBot :show_num="4"/>
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageBot from "@/components/PageBottom.vue"
	import PageNav from "@/components/PageNav.vue"
	import md5 from 'js-md5';
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	import {
		sendEmail,
	} from '@/api/contact';
	export default {
		name: 'contact',
		components: {
			PageBot,
			PageNav
			// SwiperDemo
		},
		beforeCreate() {
			let is_refresh = localStorage.getItem("load_refresh") ? localStorage.getItem("load_refresh") : "false";
			if (is_refresh == "true") {
				localStorage.setItem("load_refresh", "false"); // 存入缓存
				location.reload() // 刷新一次
			}
			// window.location.reload(true)
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {

		},

		mounted() {
			//获取滑动控件容器,灰色背景
			this.dragContainer = document.getElementById("dragContainer");
			//获取滑块左边部分,绿色背景
			this.dragBg = document.getElementById("dragBg");
			//获取滑动验证容器文本
			this.dragText = document.getElementById("dragText");
			//获取滑块
			this.dragHandler = document.getElementById("dragHandler");

			//滑块的最大偏移量 = 滑动验证容器文本长度 - 滑块长度
			this.maxHandlerOffset = dragContainer.clientWidth - dragHandler.clientWidth;

			// 初始化
			this.initDrag();

		},
		data() {
			return {
				is_dis:true,
				dragContainer: null,
				dragBg: null,
				dragText: null,
				dragHandler: null,
				maxHandlerOffset: null,
				add_form: {
					firstName:"",
					email:"",
					phone:"",
					country:"",
					city:"",
					content:"",
					company:"",
					job:""
				},
				rules: {
					firstName: [{
						required: true,
						message: "User name",
						trigger: "blur"
					}],
					email: [{
						required: true,
						message: "Email",
						trigger: "blur"
					}],
					company: [{
						required: true,
						message: "Company",
						trigger: "blur"
					}],
					country: [{
						required: true,
						message: "Country",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: "Message content",
						trigger: "blur"
					}],
					

				},
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				show_top: false,
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
			}
		},
		methods: {
			open_whats(){
				window.open(this.whats_url)
			},
			open_skype(){
				window.open(this.skype_url)
			},
			sub_form(){
				this.$refs["add_form"].validate((valid) => {
					if (valid) {
						this.add_form.timestamp = new Date().getTime();
						this.add_form.sign = md5("time=" + this.add_form.timestamp + "&content=" + this.add_form.content + "&key=wFFj26NUCzAp8rnrAGXG" )
						sendEmail(this.add_form).then(res=>{
							this.$message.success(res.data)
							this.initDrag() // 初始化
						})
					}
				});
			},
			// 初始化
			initDrag() {
				this.is_dis = true;
				//在滑动验证容器文本写入“拖动滑块验证”
				this.dragText.textContent = "Please slide to verify";
				//给滑块添加鼠标按下监听
				this.dragHandler.addEventListener("mousedown", this.onDragHandlerMouseDown);

				//初始化滑块移动量
				this.dragHandler.style.left = 0;
				//初始化绿色背景
				this.dragBg.style.width = 0;
				this.dragText.style.color = "";
				this.dragHandler.setAttribute("class", "dragHandlerBg");
			},
			// 鼠标按下
			onDragHandlerMouseDown() {
				//鼠标移动监听
				document.addEventListener("mousemove", this.onDragHandlerMouseMove);
				//鼠标松开监听
				document.addEventListener("mouseup", this.onDragHandlerMouseUp);
			},
			// 鼠标移动
			onDragHandlerMouseMove() {
				//滑块移动量
				let dragX = this.dragBg?.getBoundingClientRect() || {}
				var left = event.clientX - this.dragHandler.clientWidth / 2 - dragX.left;

				if (left < 0) { // 滑动小于0设为0
					left = 0;
				} else if (left >= this.maxHandlerOffset) { // 滑动超过最大偏移量则校验成功
					left = this.maxHandlerOffset;
					this.verifySucc();
				}
				// 超过最大偏移量，不增加绿色区域宽度
				if (left > this.maxHandlerOffset) return;
				// 滑块移动量（距离左侧的定位）
				this.dragHandler.style.left = left + "px";
				// 绿色背景的长度
				this.dragBg.style.width = left + "px";
			},
			// 鼠标抬起
			onDragHandlerMouseUp() {
				//移除鼠标移动监听
				document.removeEventListener("mousemove", this.onDragHandlerMouseMove);
				//移除鼠标松开监听
				document.removeEventListener("mouseup", this.onDragHandlerMouseUp);
				//初始化滑块移动量
				this.dragHandler.style.left = 0;
				//初始化绿色背景
				this.dragBg.style.width = 0;
			},
			// 滑动完成
			verifySucc() {
				console.log("验证通过")
				this.is_dis = false;
				//容器文本的文字改为白色“验证通过”字体
				this.dragText.textContent = "Successful";
				this.dragText.style.color = "white";
				//验证通过的滑块背景
				this.dragHandler.setAttribute("class", "dragHandlerOkBg");
				//移除鼠标按下监听
				this.dragHandler.removeEventListener("mousedown", this.onDragHandlerMouseDown);
				//移除 鼠标移动监听
				document.removeEventListener("mousemove", this.onDragHandlerMouseMove);
				//移除鼠标松开监听
				document.removeEventListener("mouseup", this.onDragHandlerMouseUp);
				// alert('操作成功')
			},
			
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 600px;
		background-image: url("../../assets/images/contact/top_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		color: #FFFFFF;

		.cen-text {
			margin-top: 180px;
			font-size: 60px;
			
		}

		.bot-text {
			font-size: 22px;
			width: 872px;
			margin: 0 auto;
			margin-top: 15px;
			color: #FFFFFF;
		}


	}



	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
		.page_2 {
			padding-top: 10px;
			width: 100%;
			padding-bottom: 150px;
			min-width: 1360px;
			background: #FFFFFF;
		}
	
		.page_2_tab {
			width: 1360px;
			margin: 0 auto;
			background: #FFFFFF;
			border-radius: 15px;
			box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
			margin-top: -120px;
			display: flex;
			flex-direction: column;
	
			.tab_top {
				width: 94%;
				margin: 0 auto;
				margin-top: 46px;
				display: flex;
				align-items: center;
				justify-content: space-between;
	
				.tab_left {
					display: flex;
					align-items: center;
					color: #000000;
					font-size: 36px;
	
					.tab_left_img {
						width: 66px;
						height: 66px;
						margin-right: 20px;
					}
				}
	
				.tab_center {
					flex: 1;
					text-align: right;
					color: #565656;
					font-size: 16px;
				}
	
				.tab_right {
					margin-left: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
	
					.tab_right_img {
						cursor: pointer;
						margin-left: 10px;
						width: 80px;
						height: 80px;
					}
				}
			}
	
			.tab_cent {
				width: 94%;
				// height: 500px;
				// background-color: aqua;
				margin: 0 auto;
				margin-top: 40px;
				padding-bottom: 80px;
	
				.el-form-item-list {
					display: inline-block;
					vertical-align: top;
					margin: 5px 10px;
					margin-bottom: 15px;
					width: 48%;
				}
	
			}
	
			.sub-box {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 50px;
				
				.flex-center {
						display: flex;
						align-items: center;
					}
				
					.btn {
						border: 1px solid blue;
						width: 60px;
						text-align: center;
						color: blue;
						height: 33px;
						line-height: 33px;
						margin-left: 15px;
						cursor: pointer;
					}
				
					/* 滑动控件容器,灰色背景 */
					#dragContainer {
						position: relative;
						display: inline-block;
						background: #e8e8e8;
						width: 300px;
						height: 33px;
						border: 2px solid #e8e8e8;
					}
				
					/* 滑块左边部分,绿色背景 */
					#dragBg {
						position: absolute;
						background-color: #7ac23c;
						width: 0px;
						height: 100%;
					}
				
					/* 滑动验证容器文本 */
					#dragText {
						position: absolute;
						width: 100%;
						height: 100%;
						/* 文字水平居中 */
						text-align: center;
						/* 文字垂直居中,这里不能用百分比,因为百分比是相对原始line-height的,而非div高度 */
						line-height: 33px;
						/* 文本不允许选中 */
						user-select: none;
						-webkit-user-select: none;
					}
				
					/* 滑块 */
					#dragHandler {
						position: absolute;
						width: 40px;
						height: 100%;
						cursor: move;
					}
				
					/* 滑块初始背景 */
					.dragHandlerBg {
						background: #fff no-repeat center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==");
					}
				
					/* 验证成功时的滑块背景 有√*/
					.dragHandlerOkBg {
						background: #fff no-repeat center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==");
					}
				.sub-input {
					width: 300px;
				}
	
				.coe-img {
					width: 103px;
					height: 45px;
					margin-left: 10px;
					margin-right: 30px;
					background-color: aqua;
				}
	
				.sub-btn {
					margin-left: 20px;
					cursor: pointer;
					position: relative;
					z-index: 1;
					width: 146px;
					height: 47px;
					border: 0;
					// background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
					// box-shadow: 0px 6px 20px 0px #ABDBF7;
					color: #ffffff;
					font-size: 18px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
	
					.bott-img {
						margin-left: 14px;
						width: 8px;
						height: 8px;
					}
				}
	
			}
	
		}
	
	
	
	
	
	
</style>
<style scoped>
	/deep/ .el-form-item__label {
		text-align: left;
	}

	/deep/ .el-form-item-list .el-input__inner {
		width: 620px;
	}

	/deep/ .el-switch__label span {
		font-size: 16px;
	}

	/deep/ .el-textarea__inner {
		width: 1250px;
	}
</style>