import request from '@/utils/request';



// 发送邮件
export function sendEmail(data) {
  return request({
    url: '/ops-dev-api/api/ows/message/send',
    method: 'post',
	data:data
  })
}